<template>
  <section class="lang-switch-bg">
    <ul class="lang-switch">
      <li
        class="li"
        v-for="(item, index) in langList"
        :key="index"
        @click="checkLang(index)"
      >
        <div class="left">
          <h1 class="h1">{{ item.title }}</h1>
          <p class="p">{{ item.desc }}</p>
        </div>
        <img
          class="check-icon"
          v-show="index === +tabIndex"
          src="@/assets/images/alexa/ic_24_selected@3x.png"
        />
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: ["appId"],
  computed: {
    ...mapState("alexaLogin", ["langList", "tabIndex"]),
  },
  created() {
    // document.title = "Language";
  },
  methods: {
    ...mapMutations("alexaLogin", ["setTabIndex"]),
    checkLang(index) {
      this.setTabIndex(index);
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.lang-switch-bg {
  background: #f4f4f4;
  height: 100vh;
}
.lang-switch {
  padding-left: 16px;
  background: #fff;
  .li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding-right: 12px;
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);
    &:last-child {
      border: none;
    }

    .left {
      .h1 {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
      }
      .p {
        font-size: 12px;
        line-height: 14px;
        margin-top: 4px;
      }
    }
    .check-icon {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
